import { useEffect, useState } from "react";

export default function Profile(props) {
  const { name, host, primary, secondary, design, display, rotation, onClick } =
    props;
  const [primaryColor, setPrimaryColor] = useState("red");
  const [secondaryColor, setSecondaryColor] = useState("white");
  const [currentRotation, setCurrentRotation] = useState(0);


  useEffect(() => {
    switch (primary) {
      case null:
      case "red":
      default:
        setPrimaryColor("hue-rotate-[0deg] brightness-[0.75]");
        // setPrimaryColor("bg-rose-800");
        break;
      case "orange":
        setPrimaryColor("hue-rotate-[30deg]");
        // setPrimaryColor("bg-orange-700");
        break;
      case "yellow":
        setPrimaryColor("hue-rotate-[55deg] brightness-[2.2] ");
        // setPrimaryColor("bg-yellow-600");
        break;
      case "green":
        setPrimaryColor("hue-rotate-[90deg]");
        // setPrimaryColor("bg-lime-600");
        break;
      case "blue":
        setPrimaryColor("hue-rotate-[170deg] ");
        // setPrimaryColor("bg-sky-600");
        break;
      case "indigo":
        setPrimaryColor("hue-rotate-[240deg] brightness-[0.7]");
        // setPrimaryColor("bg-indigo-600");
        break;
      case "violet":
        setPrimaryColor("hue-rotate-[270deg]");
        // setPrimaryColor("bg-fuchsia-800");
        break;
    }
  }, [primary]);

  useEffect(() => {
    switch (secondary) {
      case null:
      case "white":
      default:
        setSecondaryColor("grayscale invert brightness-[0.9]");
        // setSecondaryColor("bg-gray-200");
        break;
      case "lightGray":
        setSecondaryColor("grayscale contrast-[0.3] invert brightness-[1]");
        // setSecondaryColor("bg-gray-400");
        break;
      case "darkGray":
        setSecondaryColor("grayscale contrast-[0.1] brightness-[0]");
        // setSecondaryColor("bg-gray-600");
        break;
      case "black":
        setSecondaryColor("grayscale brightness-[0.75]");
        // setSecondaryColor("bg-gray-800");
        break;
      case "lightBrown":
        setSecondaryColor("hue-rotate-[40deg] brightness-[0.75]");
        // setSecondaryColor("bg-orange-800");
        break;
      case "brown":
        setSecondaryColor("hue-rotate-[30deg] brightness-[0.5]");
        // setSecondaryColor("bg-orange-900");
        break;
      case "darkBrown":
        setSecondaryColor("hue-rotate-[30deg] brightness-[0.25]");
        // setSecondaryColor("bg-orange-950");
        break;
    }
  }, [secondary]);

  useEffect(() => {
    switch (rotation) {
      case null:
      case 0:
      case 360:
      default:
        setCurrentRotation("rotate-[0deg]");
        break;
      case 45:
        setCurrentRotation("rotate-[45deg]");
        break;
      case 90:
        setCurrentRotation("rotate-[90deg]");
        break;
      case 135:
        setCurrentRotation("rotate-[135deg]");
        break;
      case 180:
        setCurrentRotation("rotate-[180deg]");
        break;
      case 225:
        setCurrentRotation("rotate-[225deg]");
        break;
      case 270:
        setCurrentRotation("rotate-[270deg]");
        break;
      case 315:
        setCurrentRotation("rotate-[315deg]");
        break;
    }
  }, [rotation]);

  return (
    <button
      onClick={onClick}
      className={`h-[100%] aspect-square select-none max-w-[150px] shrink-1 relative`}
    >
      <div>
        <div className=" overflow-hidden w-[90%] aspect-square rounded-full absolute left-1/2 -translate-x-1/2 top-[50%] -translate-y-1/2 shadow-inner shadow-black/60 transition-all text-center select-none">
          {/* DESIGNS */}
          <div className={`h-full w-full ${currentRotation}  select-none`}>
            <img
              src={require("../images/shield-designs/primary.png")}
              alt=""
              className={`pointer-events-none aspect-maintain  absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-full h-full ${primaryColor} `}
              style={{filter: "brightness(0) saturate(100%) invert(79%) sepia(93%) saturate(877%) hue-rotate(335deg) brightness(109%) contrast(84%)"}}
            ></img>
            {[1, 2, 3, 4, 5, 6].map((num, index) => {
              return <div
              key={index}
                className={`${
                   design === num ? "relative" : "hidden"
                } h-full w-full`}
              >
                <img
                  src={require("../images/shield-designs/shield-design-" + num + ".png")}
                  alt=""
                  className={`pointer-events-none aspect-maintain  absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-full h-full ${secondaryColor} `}
                ></img>
              </div>;
            })}
           
          </div>

          <img
            src={require("../images/shield-designs/shield.png")}
            alt=""
            className={`pointer-events-none aspect-maintain  absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-full h-full`}
          ></img>
        </div>

        <div
          className={`${
            name === null || name === "" ? "hidden" : ""
          } hidden whitespace-nowrap text-2xl leading-7 text-black pl-2 pr-2 pb-1 w-auto h-auto rounded-full shadow shadow-black/60 bg-gradient-to-t from-slate-300 to-slate-50  border-2 border-black transition-all text-top select-none absolute left-1/2 -translate-x-1/2 -bottom-4 align-top  justify-center`}
        >
          {name}
        </div>
        <div
          className={`${
            host ? "" : "hidden"
          } hidden w-8 h-8 absolute bg-yellow-400 border-2 border-black rounded-full -left-3 top-0 text-center text-2xl shadow shadow-black/60`}
        >
          <svg
            viewBox="0 0 1024 1024"
            fill="currentColor"
            height="1em"
            width="1em"
            className="absolute left-1/2 -translate-x-1/2 top-[8.5%]"
          >
            <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z" />
          </svg>
        </div>
      </div>
    </button>
  );
}
