import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import styles from "../styles.module.css";

export default function Background() {
  const trees = useRef(null);
  const mountains = useRef(null);
  const clouds = useRef(null);

  useEffect(() => {
    gsap.to(trees.current, {
      x: "-100%",
      duration: 10,
      repeat: -1,
      ease: "none",
    });
    gsap.to(mountains.current, {
      x: "-100%",
      duration: 60,
      repeat: -1,
      ease: "none",
    });
    gsap.to(clouds.current, {
      x: "-25%",
      duration: 120,
      repeat: -1,
      ease: "none",
    });
  }, []);

  return (
    <div className="w-full h-dvh absolute">
      <div className="w-full h-dvh bg-gradient-to-t from-orange-400 from-5% via-yellow-500 via-15% to-pink-700 to-100% absolute">
        <div className={styles.sun}>
          <div className={styles.ray_box}>
            <div className={[styles.ray, styles.ray1].join(" ")}></div>
            <div className={[styles.ray, styles.ray2].join(" ")}></div>
            <div className={[styles.ray, styles.ray3].join(" ")}></div>
            <div className={[styles.ray, styles.ray4].join(" ")}></div>
            <div className={[styles.ray, styles.ray5].join(" ")}></div>
            <div className={[styles.ray, styles.ray6].join(" ")}></div>
            <div className={[styles.ray, styles.ray7].join(" ")}></div>
            <div className={[styles.ray, styles.ray8].join(" ")}></div>
            <div className={[styles.ray, styles.ray9].join(" ")}></div>
            <div className={[styles.ray, styles.ray10].join(" ")}></div>
          </div>
        </div>

        <div
          className={` w-max  aspect-maintain flex justify-start  align-top  flex-nowrap absolute bottom-[25%] `}
          ref={clouds}
        >
          <img
            src={require("../images/clouds.png")}
            className={` opacity-25 max-h-[10vw] backdrop-blur`}
            alt=""
          ></img>
          <img
            src={require("../images/clouds.png")}
            className={` opacity-25 max-h-[10vw] backdrop-blur`}
            alt=""
          ></img>
          <img
            src={require("../images/clouds.png")}
            className={` opacity-25 max-h-[10vw] backdrop-blur`}
            alt=""
          ></img>
          <img
            src={require("../images/clouds.png")}
            className={` opacity-25 max-h-[10vw] backdrop-blur`}
            alt=""
          ></img>
        </div>

        <div className={`h-full w-screen absolute hidden`} ref={mountains}>
          <img
            src={require("../images/mountains.webp")}
            className={`absolute bottom-0 `}
            alt=""
          ></img>
          <img
            src={require("../images/mountains.webp")}
            className={`absolute left-[100%] bottom-0`}
            alt=""
          ></img>
        </div>

        <div
          className={`h-full w-[50%]  hidden absolute bottom-[-6vw]`}
          ref={trees}
        >
          <img
            src={require("../images/trees.webp")}
            className={`absolute left-0 bottom-0`}
            alt=""
          ></img>
          <img
            src={require("../images/trees.webp")}
            className={`absolute left-[100%] bottom-0`}
            alt=""
          ></img>
          <img
            src={require("../images/trees.webp")}
            className={`absolute left-[200%] bottom-0`}
            alt=""
          ></img>
          <img
            src={require("../images/trees.webp")}
            className={`absolute left-[300%] bottom-0`}
            alt=""
          ></img>
        </div>

        {/* <div
          className={`${
            orientation === "landscape" ? "hidden" : "absolute"
          } left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-screen h-screen bg-black/75 backdrop-blur`}
        ></div> */}
      </div>
    </div>
  );
}
