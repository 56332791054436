export default function Container(props) {
  const {
    children,
    horizontal,
    justifyContent,
    alignItems,
    display,
    reverse,
    gap,
    tall,
    wide, 
    background,
    halve
  } = props;

  // const [visible, setVisible] = useState(true);
  // const [x, setX] = useState(0);

  // const delay = useCallback((delay, method, parameter) => {
  //   const timeout = setTimeout(() => {
  //     method(parameter);
  //   }, delay)
  //   return () => clearTimeout(timeout);
  // }, []);

  const style = {
    flexDirection: horizontal
      ? reverse
        ? "row-reverse"
        : "row"
      : reverse
      ? "column-reverse"
      : "column",
    justifyContent,
    alignItems,
    display,
    gap: gap ? gap : "0px",
  };

  // const fade = useCallback((visible) => {

  // }, []);

  // useEffect(() => {
  //   if (display === "none") {
  //     setX(100);
  //     delay(500, setVisible, false);
  //   } else {
  //     delay(500, setVisible, true);
  //     delay(1000, setX, 0);
  //   }
  // }, [display, visible, setVisible, setX, delay]);

  return (
      <div
        style={style}
        className={` ${tall ? "h-full" : "h-fit"} ${
          background ? "bg-wood border-4 border-black/50 rounded-3xl shadow shadow-black" : ""
        } ${wide ? halve ? "w-[50%]" : "w-full" : "w-fit"} flex  relative items-center transition-all duration-500 p-1 
          `}
      >
        {children}
      </div>
  );
}
