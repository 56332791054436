import { useCallback, useEffect, useState } from "react";
import Button from "../components/Button";
import { socket } from "../socket";
import Textplate from "../components/Textplate";
import Container from "../components/Container";
import Profile from "../components/Profile";
import InputSelector from "../components/InputSelector";
import Role from "../components/Role";
import Player from "../components/Player";

export default function Lobby(props) {
  const { username, room, users, host, orientation, roles } = props;

  const [timeLimit, setTimeLimit] = useState(3);
  const [viewedRole, setViewedRole] = useState(0);

  const leaveRoom = useCallback(() => {
    socket.emit("leave");
  }, []);

  const startGame = useCallback(() => {
    console.log("Starting game.");
    socket.emit("start", { room, timeLimit });
  }, [room, timeLimit]);

  useEffect(() => {
    console.log("roles", roles.length);
  }, [roles]);

  return (
    <div
      className={`flex flex-col items-center justify-end gap-10 w-screen h-screen  bg-gradient-to-t from-orange-400 from-5% via-yellow-500 via-15% to-pink-700 to-100%`}
    >
      <div
        className={`portrait:hidden landscape:flex w-full h-full justify-center items-center gap-1`}
      >
        <Button
          onClick={() =>
            setViewedRole(
              viewedRole === 0 ? Object.keys(roles).length - 1 : viewedRole - 1
            )
          }
        ></Button>
        {roles &&
          Object.values(roles).map((role, index) => {
            return (
              <Role
                role={role}
                visible={viewedRole === index}
                key={index}
              ></Role>
            );
          })}
        <Button
          onClick={() =>
            setViewedRole(
              viewedRole === Object.keys(roles).length - 1 ? 0 : viewedRole + 1
            )
          }
        ></Button>
      </div>

      <div
        className={`portrait:flex landscape:hidden w-full h-full justify-start flex-col items-center`}
      >
        <div
          className={`w-full h-[15%] border-b-4 border-black/10 bg-black/20 flex  items-center justify-around portrait:flex landscape:hidden`}
        >
          <Player username={username} selectable={false}></Player>

          <Textplate>ROOM {room}</Textplate>
        </div>
        <div className={`flex w-full h-[60%] bg-black/10`}>
          <div
            className={`flex flex-col gap-1 overflow-y-scroll  w-full h-full justify-center items-center`}
          >
            {users.map((user, index) => {
              // console.log(user);
              // let shield = user["shield"];
              return user["username"] !== username ? (
                <Player
                  key={index}
                  highlight={user["username"] === username ? "yellow" : null}
                  username={user["username"]}
                  selectable={false}
                ></Player>
              ) : null;
              // <Profile
              //   key={index}
              //   host={user["username"] === host ? true : false}
              //   primary={shield["primaryColor"]}
              //   secondary={shield["secondaryColor"]}
              //   design={shield["design"]}
              //   rotation={shield["rotation"]}
              //   name={user["username"]}
              // ></Profile>
            })}
          </div>
        </div>
        <div className={` w-full h-[25%] flex flex-col items-center justify-center`}>
          {host === username ? (
            <InputSelector
              elements={[3, 5, 10, 15, 180, 300]}
              setValue={setTimeLimit}
            ></InputSelector>
          ) : null}
          <div className={`flex w-full h-1/2 items-center justify-center gap-1`}>
          {host === username && users.length >= 3 ? (
            <Button onClick={startGame}>START</Button>
          ) : null}
          <Button onClick={leaveRoom}>LEAVE</Button>

          </div>
        </div>
      </div>
    </div>
  );
}
