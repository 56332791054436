import { useCallback, useEffect, useState } from "react";

export default function Player(props) {
  const {
    username,
    highlight,
    selectFunction,
    selected,
    selectable = true,
    state = null,
  } = props;

  // useEffect(() => {
  //   console.log(highlight);
  // }, [highlight]);
  return (
    <div
      className={`relative  text-3xl  min-w-[200px] w-fit min-h-[50px] bg-cover bg-center  bg-bronze border-4 rounded-xl border-neutral-900 select-none  text-center select-none text-center flex items-center justify-center  ${
        highlight === "yellow"
          ? "text-yellow-200"
          : highlight === "red"
          ? "text-red-300"
          : highlight === "blue"
          ? "text-blue-300"
          : "text-neutral-300"
      } `}
      onClick={selectable ? selectFunction : null}
    >
      {username ? username : "Nobody"}
      {selectable ? (
        <button
          onClick={selectFunction}
          className={`rounded-full  absolute border-4 bg-neutral-300 border-neutral-900 w-[50px] aspect-square -right-6`}
        >
          {selected ? (
            <div
              className={`rounded-full  absolute border-0 bg-neutral-900 border-black/50 w-[30px] aspect-square left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 pointer-events-none`}
            ></div>
          ) : null}
        </button>
      ) : null}
      {state ? (
        <div
          className={`rounded-full  absolute border-4 ${
            state === "alive"
              ? "bg-green-600"
              : state === "exiled"
              ? "bg-neutral-500"
              : "bg-red-800"
          } border-neutral-900 w-[50px] aspect-square -left-6`}
        ></div>
      ) : null}
    </div>
  );
}
