import { useEffect, useRef } from "react";
import Profile from "./Profile";
import { gsap } from "gsap";

export default function Longship(props) {
  const { host, users, shield } = props;

  const ship = useRef(null);
  const sail = useRef(null);

  useEffect(() => {
    // let tl = gsap.timeline({repeat: -1})
    // tl.to(ship.current, {
    //   y: "-5%",
    //   duration: 10,
    //   ease: "elastic",
    // });
    // tl.to(ship.current, {
    //   y: "0%",
    //   duration: 10,
    //   ease: "elastic",
    // });
    gsap.to(ship.current, {
      y: "-3%",
      duration: 3,
      repeat: -1,
      yoyo: true,
      // yoyoEase: true
    });

    gsap.to(ship.current, {
      rotation: 2,
      duration: 3,
      repeat: -1,
      yoyo: true,
      // yoyoEase: true
    });

    // gsap.to(sail.current,
    //   {
    //     y: 10,
    //     duration: 4,
    //     repeat: -1,
    //     yoyo: true,
    //     yoyoEase: true
    //   });

    // gsap.to(sail.current,
    //   {
    //     rotation: 95,
    //     duration: 2,
    //     repeat: -1,
    //     yoyo: true,
    //     yoyoEase: true
    //   });
  }, []);

  return (
    <div
      className={`w-1/2 absolute bottom-[-1%] right-[5%] 
        "flex"`}
      ref={ship}
    >
      <img src={require("../images/longship.png")} alt=""></img>
      <div
        className={`w-[75%] h-[4vw] bg-black/0  absolute flex bottom-[6%] left-1/2 -translate-x-1/2 justify-start wrap-none items-center gap-1`}
      >
        {users ? (
          users.map((user, index) => {
            console.log(user);
            let shield = user["shield"];
            return (
              <Profile
                key={index}
                host={user["username"] === host ? true : false}
                primary={shield["primaryColor"]}
                secondary={shield["secondaryColor"]}
                design={shield["design"]}
                rotation={shield["rotation"]}
                name={user["username"]}
              ></Profile>
            );
          })
        ) : shield ? (
          <Profile
            primary={shield["primaryColor"]}
            secondary={shield["secondaryColor"]}
            design={shield["design"]}
            rotation={shield["rotation"]}
          ></Profile>
        ) : null}
      </div>
    </div>
  );
}
