import { useCallback, useEffect, useState } from "react";
import { socket } from "../socket";

export default function Notification() {


  const [notification, setNotification] = useState("");

  
  const delay = useCallback((delay, method, parameter) => {
    const timeout = setTimeout(() => {
      method(parameter);
    }, delay)
    return () => clearTimeout(timeout);
  }, []);
  const showNotification = useCallback(
    (message) => {
      setNotification(message);
      console.log(message);
      delay(3000, setNotification, "")
    },
    [setNotification, delay]
  );

      
  useEffect(() => {
    socket.on("error", showNotification);

    return () => {
      socket.on("error", showNotification);
    };
  }, [showNotification]);

    const style = {
        display: notification === "" ? "none" : "block"
    };
    return (
      <div 
        style={style}
      className="pointer-events-none backdrop-blur shadow text-3xl w-screen h-16 shadow-black/50 bg-white/10 border-4 border-white/50 transition-all select-none text-red-900/90 active:text-cyan-900 select-none absolute top-0 flex items-center justify-center">
        <div className="border-4 border-red-500/90 w-full h-full  text-center flex items-center justify-center">
          {notification}
        </div>
      </div>
    );
  }
  