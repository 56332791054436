export default function Button(props) {
  const { children, onClick, display, circle, small, selected } = props;
  const style = {
    display: display === "none" ? "none" : display,
  };
  return (
    <button
      style={style}
      className={`max-w-48 shrink-0 ${
        circle ? "h-20 rounded-full w-20" : "h-24 w-full rounded-3xl"
      } 
      relative
      text-green-950/90
      
 bg-gradient-to-tr ${
   selected
     ? "from-green-500 via-green-700 to-green-600"
     : "from-green-400 via-green-600 to-green-500"
 }  flex backdrop-blur text-5xl max-w-48   border-4 border-black/50   select-none items-center justify-center`}
      onClick={onClick}
    >
      <div
        className={`${
          selected
            ? "shadow-[0_0_0_0_rgb(0,0,0,0.1),inset_0_2px_4px_3px_rgb(0,0,0,0.05),0_0_0_0_rgb(0,0,0,0.1)]"
            : "shadow-[0_4px_6px_-1px_rgb(0,0,0,0.1),inset_0_0_0_0_rgb(0,0,0,0.05),0_2px_4px_-2px_rgb(0,0,0,0.1)]"
        } shadow-black ${circle ? "w-20 h-20 rounded-full" : "w-full h-full rounded-3xl"}  absolute  transition-shadow duration-500`}
      ></div>
      <div className={` w-full h-full overflow-hidden ${circle ? "rounded-full" : "rounded-3xl"} absolute `}>
        <div
          className={`-top-3 ${
            selected ? "right-6" : "right-7"
          }  ${circle ? "w-[25%]" : "w-[15%]"}  h-[200%] bg-white/15 absolute rotate-[12deg] transition-all duration-500`}
        ></div>
        <div
          className={`-top-3 ${
            selected ? "right-2" : "right-3"
          }  ${circle ? "w-[10%]" : "w-[5%]"} w-[10%] h-[200%] bg-white/15 absolute rotate-[12deg] transition-all duration-500`}
        ></div>
      </div>
      <div
        className={`absolute ${
          selected
            ? "left-[50%] -translate-x-[47%]"
            : "left-[50%] -translate-x-[50%]"
        } transition-all duration-500`}
      >
        {children}
      </div>
    </button>
  );
}
