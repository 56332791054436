import { useCallback, useEffect, useState } from "react";
import { socket } from "../socket";
import Container from "../components/Container";
import Button from "../components/Button";
import Textplate from "../components/Textplate";
import Player from "../components/Player";
import Role from "../components/Role";

export default function Game(props) {
  const {
    username,
    room,
    users,
    host,
    role,
    visibleTeammates = [],
    timeLimit,
    roles,
  } = props;

  const [time, setTime] = useState(timeLimit);
  const [targetedPlayers, setTargetedPlayers] = useState([null]);
  const [votedPlayers, setVotedPlayers] = useState([null]);

  const [usernames, setUsernames] = useState(
    users.map((user) => {
      return user["username"];
    })
  );
  const [latestDead, setLatestDead] = useState([]);
  const [currentDead, setCurrentDead] = useState([]);

  const [latestExiles, setLatestExiles] = useState([]);
  const [currentExiles, setCurrentExiles] = useState([]);
  const [returnedExiles, setReturnedExiles] = useState([]);

  const [latestInfo, setLatestInfo] = useState([]);

  const [phase, setPhase] = useState("lunar");
  const [moon, setMoon] = useState(0);
  const [cooldown, setCooldown] = useState(0);

  const [winner, setWinner] = useState(null);
  const [viewedRole, setViewedRole] = useState(0);

  let timer;

  const returnToLobby = useCallback(() => {
    console.log("Returning to lobby.");
    socket.emit("lobby", { room });
  }, [room]);

  const vote = useCallback(() => {
    socket.emit("vote", { room, votedPlayers, username });
  }, [room, username, votedPlayers]);

  const action = useCallback(() => {
    socket.emit("action", { room, targetedPlayers, username, role });
    if (!targetedPlayers.includes(null)) {
      setCooldown(role["cooldown"]);
    } else if (cooldown > 0) {
      setCooldown(cooldown - 1);
    }
  }, [room, targetedPlayers, username, role, cooldown]);

  const getInfo = useCallback(
    (dead, exiled) => {
      let updatedDead = [...currentDead];
      let newDead = [];

      dead.forEach((deadPlayer) => {
        if (!updatedDead.includes(deadPlayer)) {
          updatedDead.push(deadPlayer);
          newDead.push(deadPlayer);
        }
      });
      // console.log("New Dead: ", newDead);
      setCurrentDead(updatedDead);
      setLatestDead(newDead);

      let updatedExiles = [...currentExiles];
      let returnedExiles = [];

      updatedExiles.forEach((exiledPlayer) => {
        if (!exiled.includes(exiledPlayer)) {
          returnedExiles.push(exiledPlayer);
          let index = updatedExiles.indexOf(exiledPlayer);
          updatedExiles.splice(index, 1);
        }
      });

      // console.log("Returned Exiles: ", returnedExiles);
      setReturnedExiles(returnedExiles);

      let newExiles = [];

      exiled.forEach((exiledPlayer) => {
        if (!updatedExiles.includes(exiledPlayer)) {
          updatedExiles.push(exiledPlayer);
          newExiles.push(exiledPlayer);
        }
      });

      // console.log("New Exiles: ", newExiles);
      setCurrentExiles(updatedExiles);
      setLatestExiles(newExiles);
    },
    [currentDead, currentExiles]
  );

  const learn = useCallback((info) => {
    console.log("learned", info);
    setLatestInfo(info);
  }, []);

  const getExiles = useCallback(
    (exiles) => {
      let newExiles = exiles.map((user) => {
        return user["username"];
      });
      let updatedExiles = [...currentExiles];
      newExiles.forEach((exiledPlayer) => {
        updatedExiles.push(exiledPlayer);
      });
      setCurrentExiles(updatedExiles);
      setLatestExiles(newExiles);
    },
    [currentExiles]
  );

  const start_timer = useCallback(() => {
    clearInterval(timer);
    timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);
  }, []);

  const changePhase = useCallback(
    (newPhase) => {
      if (newPhase === "solar") {
        action();
      } else if (newPhase === "lunar") {
        vote();
        setMoon(moon === 7 ? 0 : moon + 1);
      }
      setTargetedPlayers([null]);
      setVotedPlayers([null]);

      setPhase(newPhase === "lunar" ? "solarTransition" : "lunarTransition");
      setTimeout(() => {
        setPhase(newPhase);
        setTime(timeLimit);
        start_timer();
        setLatestDead([]);
        setLatestExiles([]);
        setLatestInfo([]);
        setReturnedExiles([]);
      }, 3000);
    },
    [vote, action, start_timer, timeLimit, moon]
  );

  useEffect(() => {
    start_timer();
  }, [start_timer]);

  const gameOver = useCallback(
    (winner) => {
      clearInterval(timer);
      setWinner(winner);
    },
    [timer]
  );

  useEffect(() => {
    socket.on("vote_now", vote);
    socket.on("info", getInfo);
    socket.on("learn", learn);
    socket.on("exiles", getExiles);
    socket.on("phase", changePhase);
    socket.on("game_over", gameOver);

    return () => {
      socket.off("vote_now", vote);
      socket.off("info", getInfo);
      socket.off("learn", learn);
      socket.off("exiles", getExiles);
      socket.off("phase", changePhase);
      socket.off("game_over", gameOver);
    };
  }, [vote, getInfo, changePhase, gameOver, getExiles, learn]);

  const targetPlayer = useCallback(
    (username) => {
      let index = targetedPlayers.indexOf(username);
      if (index !== -1) {
        // if username in the array
        let newTargets = [
          ...targetedPlayers.slice(0, index),
          ...targetedPlayers.slice(index + 1),
        ];
        setTargetedPlayers(newTargets.length === 0 ? [null] : newTargets);
      } else {
        // if username not in the array
        if (username === null) {
          setTargetedPlayers([null]);
        } else {
          let newTargets = [...targetedPlayers];
          newTargets.push(username);
          if (newTargets.length > role["targets"]) {
            newTargets.shift();
          }
          if (newTargets.length > 1 && newTargets.includes(null)) {
            let nullIndex = newTargets.indexOf(null);
            newTargets.splice(nullIndex, 1);
          }
          setTargetedPlayers(newTargets);
        }
      }
    },
    [targetedPlayers, setTargetedPlayers]
  );

  const votePlayer = useCallback(
    (username) => {
      let index = votedPlayers.indexOf(username);
      if (index !== -1) {
        // if username in the array
        // setVotedPlayers([
        //   ...votedPlayers.slice(0, index),
        //   ...votedPlayers.slice(index + 1),
        // ]);
      } else {
        // if username not in the array
        setVotedPlayers([username]);
      }
    },
    [votedPlayers, setVotedPlayers]
  );

  useEffect(() => {
    // usernames.map((currentUsername) => {
    //   let foundUser = users.find((u) => u.username === currentUsername);
    //   console.log("foundUser", foundUser);
    // });
    console.log("tm8", visibleTeammates);
  }, [visibleTeammates]);

  return (
    <div
      className={` flex flex-col items-center justify-start  w-screen h-full bg-gradient-to-t from-orange-400 from-5% via-yellow-500 via-15% to-pink-700 to-100%`}
    >
      <div
        className={`w-full h-[15%] border-b-4 border-black/10 bg-black/20 flex  items-center justify-around portrait:flex landscape:hidden`}
      >
        <img
          alt=""
          src={require(`../images/symbols/${
            phase === "solar" ? "sun" : moon
          }.png`)}
          className={`max-h-14 p-1`}
        ></img>
        <Player
          username={username}
          selectable={false}
          state={
            currentDead.includes(username)
              ? "dead"
              : currentExiles.includes(username)
              ? "exiled"
              : "alive"
          }
        ></Player>
        <div
          className={`text-3xl w-[100px] bg-black/20 text-center rounded-xl border-4 border-black/20`}
        >
          {`${Math.floor(time / 60)}`.padStart(2, 0)}:
          {`${time % 60}`.padStart(2, 0)}
        </div>
      </div>

      <div
        className={`justify-start items-center flex-col h-[70%] w-full bg-black/10 portrait:flex landscape:hidden`}
      >
        <div
          className={`${
            phase === "solarTransition" || phase === "lunarTransition"
              ? "flex"
              : "hidden"
          } justify-center items-center flex-col h-full w-full gap-1`}
        >
          {latestDead.map((deadPlayer, index) => {
            return <Textplate key={index}>{deadPlayer} was killed.</Textplate>;
          })}
          {latestExiles.map((exiledPlayer, index) => {
            return (
              <Textplate key={index}>{exiledPlayer} was exiled.</Textplate>
            );
          })}
          {returnedExiles.map((returnedExile, index) => {
            return (
              <Textplate key={index}>
                {returnedExile} returned from exile.
              </Textplate>
            );
          })}
          {latestInfo.map((info, index) => {
            return (
              <Textplate key={index}>
                {info[0]} {info[1] ? "took action." : "did not take action."}
              </Textplate>
            );
          })}
        </div>

        <div
          className={`${
            winner === null && phase === "lunar" ? "flex" : "hidden"
          } justify-center items-center flex-col h-full w-full gap-1 overflow-y-scroll`}
        >
          <Player
            selected={targetedPlayers.includes(null)}
            selectFunction={() => targetPlayer(null)}
            selectable={
              !currentDead.includes(username) &&
              !currentExiles.includes(username)
            }
          ></Player>
          {usernames.map((currentUsername, index) => {
            return currentUsername !== username ? (
              <Player
                username={currentUsername}
                state={
                  currentDead.includes(currentUsername)
                    ? "dead"
                    : currentExiles.includes(currentUsername)
                    ? "exiled"
                    : "alive"
                }
                selectable={
                  currentUsername !== username &&
                  !currentDead.includes(username) &&
                  !currentExiles.includes(username) &&
                  !currentDead.includes(currentUsername) &&
                  cooldown === 0 &&
                  (role["action"] === "Return"
                    ? currentExiles.includes(currentUsername)
                    : role["action"] === "Exile"
                    ? !currentExiles.includes(currentUsername)
                    : true)
                }
                selected={targetedPlayers.includes(currentUsername)}
                selectFunction={() => targetPlayer(currentUsername)}
                highlight={
                  currentUsername === username
                    ? "yellow"
                    : role["team"] === 0 &&
                      visibleTeammates.includes(currentUsername)
                    ? "red"
                    : role["team"] === 1 &&
                      visibleTeammates.includes(currentUsername)
                    ? "blue"
                    : null
                }
                key={index}
              ></Player>
            ) : null;
          })}
        </div>

        <div
          className={`${
            winner === null && phase === "solar" ? "flex" : "hidden"
          } justify-start items-center flex-col h-full w-full gap-1 py-1 overflow-y-scroll`}
        >
          <Player
            selected={votedPlayers.includes(null)}
            selectFunction={() => votePlayer(null)}
            selectable={
              !currentDead.includes(username) &&
              !currentExiles.includes(username)
            }
          ></Player>
          {usernames.map((currentUsername, index) => {
            return (
              <Player
                username={currentUsername}
                state={
                  currentDead.includes(currentUsername)
                    ? "dead"
                    : currentExiles.includes(currentUsername)
                    ? "exiled"
                    : "alive"
                }
                selectable={
                  currentUsername !== username &&
                  !currentDead.includes(username) &&
                  !currentExiles.includes(username) &&
                  !currentDead.includes(currentUsername) &&
                  !currentExiles.includes(currentUsername)
                }
                selected={votedPlayers.includes(currentUsername)}
                selectFunction={() => votePlayer(currentUsername)}
                highlight={
                  currentUsername === username
                    ? "yellow"
                    : role["team"] === 0 &&
                      visibleTeammates.includes(currentUsername)
                    ? "red"
                    : role["team"] === 1 &&
                      visibleTeammates.includes(currentUsername)
                    ? "blue"
                    : null
                }
                key={index}
              ></Player>
            );
          })}
        </div>

        <div
          className={`
            ${
              winner !== null &&
              phase !== "solarTransition" &&
              phase !== "lunarTransition"
                ? "flex"
                : "hidden"
            }
          `}
        >
          <Textplate>{winner} win!</Textplate>
        </div>
      </div>

      <div
        className={`portrait:hidden landscape:flex w-full h-full justify-center items-center gap-1`}
      >
        <Button
          onClick={() =>
            setViewedRole(
              viewedRole === 0 ? Object.keys(roles).length - 1 : viewedRole - 1
            )
          }
        ></Button>
        {roles &&
          Object.values(roles).map((currentRole, index) => {
            return (
              <Role
                role={currentRole}
                visible={viewedRole === index}
                highlight={currentRole["name"] === role["name"]}
                key={index}
              ></Role>
            );
          })}
        <Button
          onClick={() =>
            setViewedRole(
              viewedRole === Object.keys(roles).length - 1 ? 0 : viewedRole + 1
            )
          }
        ></Button>
        {host === username ? (
          <Button onClick={returnToLobby}>END</Button>
        ) : null}
      </div>
    </div>
  );
}
