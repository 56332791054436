import Background from "../components/Background";
import Longship from "../components/Longship";
import Waves from "../components/Waves";

export default function Landscape(props) {
  const { orientation, users, host, shield, debug } = props;

  return (
    <div
      className={`${debug ? "hidden" : "landscape:flex portrait:hidden"}  w-screen h-screen  flex-col items-center justify-center overflow-hidden relative select-none`}
    >
      <Background orientation={orientation}></Background>
      <Waves orientation={orientation} position={"back"}></Waves>
      <Longship
        orientation={orientation}
        users={users}
        host={host}
        shield={shield}
      ></Longship>
      <Waves orientation={orientation} position={"middle"}></Waves>
      <Waves orientation={orientation} position={"front"}></Waves>
    </div>
  );
}
