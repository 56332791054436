import { useEffect, useRef, useState } from "react";
import Profile from "./Profile";
import { gsap } from "gsap";

export default function Waves(props) {
  const { position } = props;

  const [image, setImage] = useState("../images/waterMiddle.png");

  const waves = useRef(null);
  const waves2 = useRef(null);
  const waves3 = useRef(null);

  // useEffect(() => {

  // }, [position]);
  useEffect(() => {
    gsap.to(waves.current, {
      x: "25%",
      duration: 6,
      repeat: -1,
      ease: "none",
    });

    gsap.to(waves.current, {
      y: "10%",
      duration: 1,
      repeat: -1,
      ease: "none",
      yoyo: true,
    });

    gsap.to(waves2.current, {
      x: "25%",
      duration: 12,
      repeat: -1,
      ease: "none",
    });

    gsap.to(waves2.current, {
      y: "10%",
      duration: 2,
      repeat: -1,
      ease: "none",
      yoyo: true,
    });

    gsap.to(waves3.current, {
      x: "25%",
      duration: 18,
      repeat: -1,
      ease: "none",
    });

    gsap.to(waves3.current, {
      y: "10%",
      duration: 3,
      repeat: -1,
      ease: "none",
      yoyo: true,
    });

    // gsap.to(ship.current,
    //   {
    //     y: "-5%",
    //     duration: 3,
    //     repeat: -1,
    //     yoyo: true,
    //     // yoyoEase: true
    //   });
  }, []);

  return (
    // <div
    //   className={`w-fit min-w-100% h-full ${
    //     orientation === "landscape" ? "absolute" : "hidden"
    //   } bottom-0 inline-block`}
    // >

    <div
      className={`w-full h-full absolute bottom-0 flex justify-end align-end`}
    >
      <div
        className={` w-max  aspect-maintain ${
          position === "back" ? "flex" : "hidden"
        } justify-end align-end items-end flex-nowrap absolute bottom-0`}
        ref={waves3}
      >
        <img
          src={require("../images/waterBack.png")}
          alt=""
          className={`aspect-maintain max-h-[3.5vw]`}
        ></img>
        <img
          src={require("../images/waterBack.png")}
          alt=""
          className={`aspect-maintain max-h-[3.5vw]`}
        ></img>
        <img
          src={require("../images/waterBack.png")}
          alt=""
          className={`aspect-maintain max-h-[3.5vw]`}
        ></img>
        <img
          src={require("../images/waterBack.png")}
          alt=""
          className={`aspect-maintain max-h-[3.5vw]`}
        ></img>
      </div>

      <div
        className={` w-max ${
          position === "middle" ? "flex" : "hidden"
        } justify-end flex-nowrap absolute bottom-0`}
        ref={waves2}
      >
        <img
          src={require("../images/waterMiddle.png")}
          alt=""
          className={`aspect-maintain max-h-[2.5vw]`}
        ></img>
        <img
          src={require("../images/waterMiddle.png")}
          alt=""
          className={`aspect-maintain max-h-[2.5vw]`}
        ></img>
        <img
          src={require("../images/waterMiddle.png")}
          alt=""
          className={`aspect-maintain max-h-[2.5vw]`}
        ></img>
        <img
          src={require("../images/waterMiddle.png")}
          alt=""
          className={`aspect-maintain max-h-[2.5vw]`}
        ></img>
      </div>

      <div
        className={` w-max  aspect-maintain ${
          position === "front" ? "flex" : "hidden"
        } justify-end align-end items-end flex-nowrap absolute bottom-0`}
        ref={waves}
      >
        <img
          src={require("../images/waterFront.png")}
          alt=""
          className={`aspect-maintain max-h-[1.5vw]`}
        ></img>
        <img
          src={require("../images/waterFront.png")}
          alt=""
          className={`aspect-maintain max-h-[1.5vw]`}
        ></img>
        <img
          src={require("../images/waterFront.png")}
          alt=""
          className={`aspect-maintain max-h-[1.5vw]`}
        ></img>
        <img
          src={require("../images/waterFront.png")}
          alt=""
          className={`aspect-maintain max-h-[1.5vw]`}
        ></img>
      </div>
    </div>

    // <div
    //   className={`h-fit  absolute bottom-0 flex justify-end flex-nowrap`}
    //   ref={waves}
    // >
    //   <img
    //     src={require("../images/water.png")}
    //     // className={`absolute bottom-0 right-0 max-w-fit`}
    //     alt=""
    //   ></img>
    //   <img
    //     src={require("../images/water.png")}
    //     // className={`absolute right-[100%] bottom-0 max-w-fit`}
    //     alt=""
    //   ></img>
    //   <img
    //     src={require("../images/water.png")}
    //     // className={`absolute right-[200%] bottom-0`}
    //     alt=""
    //   ></img>
    //   <img
    //     src={require("../images/water.png")}
    //     // className={`absolute right-[200%] bottom-0`}
    //     alt=""
    //   ></img>
    // </div>
    // </div>

    // <div
    //   className={`w-[100%] h-[90px] absolute bottom-0  ${
    //     orientation === "landscape" ? "flex" : "hidden"
    //   } justify-end `}
    //   ref={waves}
    // >
    //   {[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0].map(() => (
    //   <div className={`h-[100%] aspect-square  relative overflow-hidden`}>
    //     <div
    //       className={` w-[130%] h-[110%] rotate-0 absolute top-0 overflow-hidden before:bottom-1/2 before:translate-y-[0%] before:absolute left-1/2 -translate-x-1/2 left-1/2 -translate-x-1/2 before:left-1/2 before:translate-x-[0%] before:w-[50%]  before:h-[50%] before:rounded-[100%] before:shadow-[-20px_25px_0px_25px_#1e3a8a]`}
    //     ></div>
    //     <div className={`w-full h-[40%] bg-blue-900 absolute bottom-0`}></div>
    //   </div>

    //   ))}
    //   {[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0].map(() => (
    //   <div className={`h-[100%] aspect-square  relative overflow-hidden shrink-0`}>
    //     <div
    //       className={` w-[130%] h-[110%] rotate-0 absolute top-0 overflow-hidden before:bottom-1/2 before:translate-y-[0%] before:absolute left-1/2 -translate-x-1/2 left-1/2 -translate-x-1/2 before:left-1/2 before:translate-x-[0%] before:w-[50%]  before:h-[50%] before:rounded-[100%] before:shadow-[-20px_25px_0px_25px_#1e3a8a]`}
    //     ></div>
    //     <div className={`w-full h-[40%] bg-blue-900 absolute bottom-0`}></div>
    //   </div>

    //   ))}
    //   {[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0].map(() => (
    //   <div className={`h-[100%] aspect-square  relative overflow-hidden shrink-0`}>
    //     <div
    //       className={` w-[130%] h-[110%] rotate-0 absolute top-0 overflow-hidden before:bottom-1/2 before:translate-y-[0%] before:absolute left-1/2 -translate-x-1/2 left-1/2 -translate-x-1/2 before:left-1/2 before:translate-x-[0%] before:w-[50%]  before:h-[50%] before:rounded-[100%] before:shadow-[-20px_25px_0px_25px_#1e3a8a]`}
    //     ></div>
    //     <div className={`w-full h-[40%] bg-blue-900 absolute bottom-0`}></div>
    //   </div>

    //   ))}
    //   {[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0].map(() => (
    //   <div className={`h-[100%] aspect-square  relative overflow-hidden shrink-0`}>
    //     <div
    //       className={` w-[130%] h-[110%] rotate-0 absolute top-0 overflow-hidden before:bottom-1/2 before:translate-y-[0%] before:absolute left-1/2 -translate-x-1/2 left-1/2 -translate-x-1/2 before:left-1/2 before:translate-x-[0%] before:w-[50%]  before:h-[50%] before:rounded-[100%] before:shadow-[-20px_25px_0px_25px_#1e3a8a]`}
    //     ></div>
    //     <div className={`w-full h-[40%] bg-blue-900 absolute bottom-0`}></div>
    //   </div>

    //   ))}
    // </div>
  );
}
