import { useCallback, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";


export default function InputSelector(props) {
  const {
    elements,
    editing,
    setValue
  } = props;

  const [currentElement, setCurrentElement] = useState(elements[0]);

  const changeCurrentElement = useCallback((direction) => {
    let index = elements.indexOf(currentElement);
    if (direction === "left") {
        index = (index === 0) ? elements.length - 1 : index - 1;
    } else {
        index = (index === elements.length - 1) ? 0 : index + 1;
    }
    
    let newElement = elements[index]
    setValue(newElement);
    setCurrentElement(newElement);


  }, [currentElement, elements, setValue]);

  useEffect(() => {
    setValue(currentElement);
  },[currentElement, setValue]);


  return (
    <div
      className={`box-border relative max-w-96 gap-2 bg-wood flex justify-center align-center items-center w-full rounded-3xl shadow-md shadow-black/50 p-2  border-4 border-black/50`}
    >
        <button
        onClick={() => changeCurrentElement("left")}
        className={`  h-16 w-16 bg-gradient-to-tr ${editing ? "from-green-500 via-green-700 to-green-600": "from-green-400 via-green-600 to-green-500"}  relative border-4 border-black/50 rounded-xl shrink-0 text-4xl flex items-center justify-center text-green-950/90 transition-all `}
      >
        <div className={` w-full h-full overflow-hidden absolute `}>
          <div
            className={`-top-3 ${editing ? "right-6" : "right-7"}  w-[25%] h-[200%] bg-white/15 absolute rotate-[12deg] transition-all duration-500`}
          ></div>
          <div
            className={`-top-3 ${editing ? "right-2" : "right-3"}  w-[10%] h-[200%] bg-white/15 absolute rotate-[12deg] transition-all duration-500`}
          ></div>
        </div>
        <FontAwesomeIcon
          icon={faCaretLeft}
          className={`${editing ? "left-[50%] -translate-x-[43%]" : "left-[50%] -translate-x-[50%]"} absolute transition-all duration-500`}
        />
        <div
          className={`${
            editing
              ? "shadow-[0_0_0_0_rgb(0,0,0,0.1),inset_0_2px_4px_3px_rgb(0,0,0,0.05),0_0_0_0_rgb(0,0,0,0.1)]"
              : "shadow-[0_4px_6px_-1px_rgb(0,0,0,0.1),inset_0_0_0_0_rgb(0,0,0,0.05),0_2px_4px_-2px_rgb(0,0,0,0.1)]"
          } shadow-black w-16 h-16 absolute rounded-xl transition-shadow duration-500`}
        ></div>
      </button>
      <p
        className={` box-border relative rounded-xl align-middle shadow-inner flex items-center justify-center text-4xl vertical- w-full h-16 shadow-black bg-black/30  border-black/50 transition-all select-none text-orange-300/80 text-center select-none outline-none`}
        
      >{currentElement}</p>
      <button
        onClick={() => changeCurrentElement("right")}
        className={` h-16 w-16 bg-gradient-to-tr ${editing ? "from-green-500 via-green-700 to-green-600": "from-green-400 via-green-600 to-green-500"}  relative border-4 border-black/50 rounded-xl shrink-0 text-4xl flex items-center justify-center text-green-950/90 transition-all `}
      >
        <div className={` w-full h-full overflow-hidden absolute `}>
          <div
            className={`-top-3 ${editing ? "right-6" : "right-7"}  w-[25%] h-[200%] bg-white/15 absolute rotate-[12deg] transition-all duration-500`}
          ></div>
          <div
            className={`-top-3 ${editing ? "right-2" : "right-3"}  w-[10%] h-[200%] bg-white/15 absolute rotate-[12deg] transition-all duration-500`}
          ></div>
        </div>
        <FontAwesomeIcon
          icon={faCaretRight}
          className={`${editing ? "left-[50%] -translate-x-[43%]" : "left-[50%] -translate-x-[50%]"} absolute transition-all duration-500`}
        />
        <div
          className={`${
            editing
              ? "shadow-[0_0_0_0_rgb(0,0,0,0.1),inset_0_2px_4px_3px_rgb(0,0,0,0.05),0_0_0_0_rgb(0,0,0,0.1)]"
              : "shadow-[0_4px_6px_-1px_rgb(0,0,0,0.1),inset_0_0_0_0_rgb(0,0,0,0.05),0_2px_4px_-2px_rgb(0,0,0,0.1)]"
          } shadow-black w-16 h-16 absolute rounded-xl transition-shadow duration-500`}
        ></div>
      </button>
    </div>
  );
}
