// import { useCallback, useEffect, useState } from "react";

import { useEffect } from "react";

export default function Role({ role, visible=true, highlight=false }) {
  const { name, action, targets, cooldown, image, punish, punishTeam } = role;

  const phaseStyles = {
    0: " rotate-[0deg]", //top-[-2%] left-[20%]
    1: " rotate-[45deg]", //top-[-2%] right-[20%]
    2: " rotate-[90deg]", //top-[25%] right-[-4%]
    3: " rotate-[135deg]", //bottom-[25%] right-[-4%]
    4: "rotate-[180deg]", //bottom-[-2%] right-[20%]
    5: " rotate-[225deg]", //bottom-[-2%] left-[20%]
    6: " rotate-[270deg]", //bottom-[25%] left-[-4%]
    7: " rotate-[315deg]", //top-[25%] left-[-4%]
  };

  const moonStyles = {
    0: " rotate-[0deg]", //top-[-2%] left-[20%]
    1: " rotate-[-45deg]", //top-[-2%] right-[20%]
    2: " rotate-[-90deg]", //top-[25%] right-[-4%]
    3: " rotate-[-135deg]", //bottom-[25%] right-[-4%]
    4: "rotate-[-180deg]", //bottom-[-2%] right-[20%]
    5: " rotate-[-225deg]", //bottom-[-2%] left-[20%]
    6: " rotate-[-270deg]", //bottom-[25%] left-[-4%]
    7: " rotate-[-315deg]", //top-[25%] left-[-4%]
  };

  // useEffect(() => {
  //   console.log("visible", visible)
  // }, [visible])

  return (
    <div
      className={`relative  p-1.5 min-w-[300px]  aspect-square rounded-3xl bg-cover bg-center     border-4  border-black  transition-all select-none   select-none   flex-col items-center justify-center gap-1.5 overflow-none ${visible ? "flex" : "hidden"}`}
    >
      {/* <div className={`absolute w-full h-full rounded-full bg-bronze ${moonStyles[moon]} transition-all duration-1000`}>
        {[0, 1, 2, 3, 4, 5, 6, 7].map((moonPhase, index) => {
          return (
            <div key={index} className={`w-full h-full absolute ${phaseStyles[moonPhase]}`}>
              <img
                alt=""
                src={require(`../images/symbols/${moonPhase}.png`)}
                className={`absolute  rounded-full w-[8%] top-[1%] aspect-square left-1/2 -translate-x-1/2 `}
              ></img>
            </div>
          );
        })}
      </div> */}

      <img
        alt=""
        src={require(`../images/${image}`)}
        className="object-cover object-top overflow-hidden absolute  leading-relaxed   rounded-2xl   "
      ></img>
      {/* <div className="object-cover object-top overflow-hidden absolute top-[10%] w-[80%]  h-[80%] leading-relaxed  border-4 border-black/75 rounded-full rounded-tr-full   ">

      </div> */}

      <div className="overflow-hidden absolute bottom-[0%] w-full h-2/5 text-white/85 bg-black border-0 border-t-0 backdrop-blur-[2px] border-black rounded-b-2xl leading-relaxed   pt-6 px-8 text-sm text-center   leading-[1] text-balance">
        {action} {targets} {action === "Return" ? "exiled " : ""}player
        {targets !== 1 && "s"}.{" "}
        {punish
          ? punishTeam
            ? `If ${targets === 1 ? "that" : "either"} player is ${
                punishTeam === "Herbivore" ? "an" : "a"
              } ${punishTeam}, you will be ${punish.toLowerCase()}${
                punish === "Exile" ? "d" : "ed"
              }.`
            : null
          : null}
        <div className={`absolute w-full h-[15%]  top-1 left-1/2 -translate-x-1/2 flex justify-center items-center gap-3`}>
        {
          [...Array(cooldown)].map(() => {
            return <div className={` h-3/4 aspect-square rounded-full bg-white`}></div>
          })
        }
        </div>
      </div>

      <div className={`overflow-hidden absolute ${highlight ? "text-yellow-200" : "text-white"}   leading-relaxed   w-full     text-center text-2xl bottom-[38%]`}>
        {name.toUpperCase()}
      </div>

      {/* {cooldown} */}
      {/* </div> */}
    </div>
  );
}
